@font-face {
  font-family: 'Mona-Sans';
  src: url('../../../fonts/Mona-Sans.woff2') format('woff2');
  /* Add more font formats (e.g., woff, ttf) for better browser compatibility */
}

hr {
  max-width: 100%;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin: 0 auto;
}

/* custom styles */

.carType {
  font-family: Mona-Sans;
  font-size: 24px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
}

.varTagline {
  font-family: Mona-Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
  padding-top: 24px;
  font-style: normal;
  padding-bottom: 40px;
}

.vehicleType {
  font-family: Mona-Sans;
  font-size: 14px;
  font-weight: 800;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-align: left;
}

/* .fleetContainer {
      padding: 6px 40px 6px 40px;
      color: #151515;
    } */

.cost {
  font-family: Mona-Sans;
  font-size: 24px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-align: left;
  padding-top: 40px;
}

.mona-sub-text-left {
  font-family: Mona-Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
}

.mona-sub-text-right {
  font-family: Mona-Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: right;
  margin-left: auto;
}

.mona-description {
  font-family: Mona-Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  padding: 24px 0;
}

.deposit-small {
  color: #151515;
  display: flex;
  font-size: 18px;
  font-family: Mona-Sans;
  font-weight: 700;
  word-wrap: break-word;
  width: 440px;
  padding-top: 24px;
}

.cost.cost-right {
  display: inline-flex;
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  width: 100%;
  /* text-align: right; */
  max-width: 240px;
  padding-top: 24px;
  align-items: flex-end;
  margin-left: auto;
  text-align: right;
  flex-direction: column-reverse;
  /* padding-right: 24px; */
  align-self: flex-end;
}

/* Media Queries */
@media screen and (max-width: 600px) {
}

/* iPhone 5/SE (320px wide) */
@media only screen and (max-width: 320px) {
  /* Your styles for iPhone 5/SE go here */
}

/* iPhone 6/7/8 (375px wide) */
@media only screen and (max-width: 375px) {
  /* Your styles for iPhone 6/7/8 go here */
}

/* iPhone 6/7/8 Plus (414px wide) */
@media only screen and (max-width: 414px) {
  /* Your styles for iPhone 6/7/8 Plus go here */
}

/* iPhone X/XS (375px wide, taller screen) */
@media only screen and (max-width: 375px) and (max-height: 812px) {
  /* Your styles for iPhone X/XS go here */
}

/* iPhone XR/XS Max (414px wide, taller screen) */
@media only screen and (max-width: 414px) and (max-height: 896px) {
  /* Your styles for iPhone XR/XS Max go here */
}

/* Samsung Galaxy S9/S10 (360px wide) */
@media only screen and (max-width: 360px) {
  /* Your styles for Samsung Galaxy S9/S10 go here */

  input[type='number'] {
    width: 50px !important;
  }
}

/* Google Pixel 2/3 (411px wide) */
@media only screen and (max-width: 411px) {
  /* Your styles for Google Pixel 2/3 go here */
  input::placeholder {
    font-family: Mona-Sans;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 1px;
    text-align: left;
    color: #9a9a9a;
    padding-left: 0px !important;
  }

  input#email {
    padding-left: 24px;
  }
  input#company {
    padding-left: 24px;
  }
}

/* iPad (768px wide, in portrait) */
@media only screen and (max-width: 768px) and (orientation: portrait) {
  /* Your styles for iPad in portrait go here */
}

/* iPad Pro (1024px wide, in landscape) */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  /* Your styles for iPad Pro in landscape go here */
}

/* Generic Mobile Devices (for smaller screens) */
@media only screen and (max-width: 480px) {
  /* Your styles for smaller mobile devices go here */
  .inputGroup {
    flex: 0 0 calc(100% - 8px) !important; /* Two inputs per row with spacing */
    max-width: calc(
      100% - 8px
    ) !important; /* Maximum width for each input group */
    flex-basis: calc(100% - 8px) !important;
  }
}

@media screen and (max-width: 390px) {
  .topLevelText {
    font-size: 28px;
  }
}

/* styles.css */

/* .vehicleQauntContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-top:24px;
  } 
  */

h3.quantity {
  font-family: Mona-Sans;
  font-size: 24px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-align: left;
  padding-top: 40px;
  text-transform: uppercase;
}

.carType {
  padding-top: 40px;
}

.vehicleType {
  /* Additional styles for .vehicleType if desired */
}

.quantity {
  /* Additional styles for .quantity if desired */
}

.vehicleAmountContainer {
  display: flex;
  justify-content: center;
  align-items: center !important;
  margin: auto;
  font-family: Mona-Sans;
  font-size: 16px;
  font-style: italic;
  width: 100%;
  max-width: 100%;
  height: 52px !important;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.5px;
}

/* .costContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

.quantity {
  padding: 0;
  margin: 0;
}

.left,
.right {
  padding: 6px 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  width: 50%;
}

.left.middle-text-p-tb {
  padding: 24px 0 40px 40px;
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

.vehicle-aliases {
  font-style: normal;
}

.left.middle-text-p-tb {
  font-style: normal;
}

.right.vehicleAmountNumber {
  font-style: normal;
}

.vehicleAmountNumber {
  padding: 24px 40px 40px 0;
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}

/* .fleetAmountContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left:40px;
    padding-right:40px;
} */

/* .depositContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
} */

/* .dapContainer {
  padding-top: 16px;
} */

.dapContainerTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 0;
}

.thankYouContainerTwoColumn {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
}

.dapContainerThree {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dueTodayContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dap {
  padding-top: 2%;
  padding-left: 40px;
  /* max-width: 500px; */
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  margin: 0;
  background-color: #f1f1f1;
  margin-bottom: 40px;
}

/* Unchecked state */
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* Set the desired width of the checkbox */
  height: 20px; /* Set the desired height of the checkbox */
  /* border: 2px solid #38b8ab; */
  background-color: transparent; /* Transparent background when unchecked */
  outline: none;
  cursor: pointer;
  border-radius: 0;
}

/* Checked state */
input[type='checkbox']:checked {
  /* background-color: #38b8ab; */

  &:before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3101_9691)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM16.3738 6.70711C16.7643 6.31658 16.7643 5.68342 16.3738 5.29289C15.9832 4.90237 15.3501 4.90237 14.9596 5.29289L8.33333 11.9191L5.70711 9.29289C5.31658 8.90237 4.68342 8.90237 4.29289 9.29289C3.90237 9.68342 3.90237 10.3166 4.29289 10.7071L7.62623 14.0404C8.01675 14.431 8.64992 14.431 9.04044 14.0404L16.3738 6.70711Z' fill='%2338B8AB'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3101_9691'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}

/* Checkbox icon (checkmark) */
input[type='checkbox']::before {
  content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3101_13605)'%3E%3Cpath d='M18.3333 0H1.66667C1.22464 0 0.800716 0.175595 0.488155 0.488155C0.175595 0.800716 0 1.22464 0 1.66667L0 18.3333C0 18.7754 0.175595 19.1993 0.488155 19.5118C0.800716 19.8244 1.22464 20 1.66667 20H18.3333C18.7754 20 19.1993 19.8244 19.5118 19.5118C19.8244 19.1993 20 18.7754 20 18.3333V1.66667C20 1.22464 19.8244 0.800716 19.5118 0.488155C19.1993 0.175595 18.7754 0 18.3333 0ZM18.3333 18.125C18.3333 18.1803 18.3114 18.2332 18.2723 18.2723C18.2332 18.3114 18.1803 18.3333 18.125 18.3333H1.875C1.81975 18.3333 1.76676 18.3114 1.72769 18.2723C1.68862 18.2332 1.66667 18.1803 1.66667 18.125V1.875C1.66667 1.81975 1.68862 1.76676 1.72769 1.72769C1.76676 1.68862 1.81975 1.66667 1.875 1.66667H18.125C18.1803 1.66667 18.2332 1.68862 18.2723 1.72769C18.3114 1.76676 18.3333 1.81975 18.3333 1.875V18.125Z' fill='%23151515'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3101_13605'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  font-size: 16px; /* Adjust the size of the checkmark */
  color: white; /* Color of the checkmark when checked */
  display: block;
  text-align: center;
  line-height: 20px; /* Center vertically within the checkbox */
  /* visibility: hidden; */
  width: 20px;
  height: 20px;
}

/* Show the checkmark when the checkbox is checked */
input[type='checkbox']:checked::before {
  visibility: visible;
}

.recommended {
  max-width: 126px;
  height: 24px;
  margin-bottom: 16px;
  background-color: #151515;
  color: white;
  font-family: Mona-Sans;
  font-size: 10px;
  font-weight: 700;
  line-height: 24px; /* Adjusted to match the height */
  text-align: center; /* Center the text horizontally */
  border-radius: 100px;
  text-transform: uppercase;
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.right button {
  font-family: Mona-Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  border: none;
  color: #151515;
  background: transparent;
  text-decoration: underline;
}

.padding-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.fleetContainer.no-p {
  padding-left: 0;
  padding-right: 0;
}

input[type='text'].quantity-input {
  background-color: #f1f1f1;
  border: none;
  width: 100px;
  height: 40px;
  padding: 0 24px 0 24px;
  border-radius: 8px;
  font-family: Mona-Sans !important;
  box-sizing: border-box;
  text-align: center;
}

form {
  /* padding: 0 40px; */
}

label {
  display: block;
}

.personal {
  padding: 0 40px;
  font-family: Mona-Sans;
  font-size: 24px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-align: left;
}

.dapAdd {
  margin-left: auto; /* Push dapAdd to the right */
}

.dapPackage {
  display: flex;
  align-items: flex-start; /* Align .dapPackage to the start */
  margin: 0; /* Remove margin to move it to the left */
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 12px;
}

.marginReset {
  margin: 0; /* Remove margin to move it to the left */
}

.dapRemove {
  margin-left: auto; /* Push dapRemove to the right */
}

.dueAmount {
}

.removeContainer {
  margin-left: auto;
}

/* Container with Flexbox aligned to the right */
.flex-container-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* Add more styles as needed */
}

/* Container with Flexbox aligned to the left */
.flex-container-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* Add more styles as needed */
}

.reservationSummary {
  display: block;
  padding-bottom: 24px;
}

.personalFirst {
  padding-bottom: 24px;
  margin: 0;
}

.reservationConfirmation {
  padding-bottom: 38px;
  margin: 0;
}

.stepper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  padding-bottom: 0px;
}

.close-button {
  padding-top: 20px !important;
}

.firstName,
.lastName,
.email,
.company {
  margin-bottom: 16px;
  color: #383a3a;
}

.firstName {
}

.email {
}

.company {
}

.inputGroup {
  flex: 0 0 calc(50% - 8px); /* Two inputs per row with spacing */
  max-width: calc(50% - 8px); /* Maximum width for each input group */
}

.input-res {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: none;
  margin: 0;
  box-sizing: border-box;
}

/* .firstName,
.lastName,
.email,
.company {
  font-family: Mona-Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
} */

/* input {
  background: #F1F1F1;
  border-radius: 8px;
  border: none;
  height: 48px;
} */

/* input::placeholder {
  font-family: Mona-Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
  color: #9A9A9A;
  padding-left: 24px;
} */

.number-container {
  font-family: Mona-Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.5px;
  text-align: center;
}

.number-container input[type='number'] {
  background-color: #f1f1f1;
  border: none;
  width: 100px;
  height: 40px;
  padding: 0 24px 0 24px;
  border-radius: 8px;
  font-weight: 700;
  font-family: Mona-Sans !important;
}

.bottom-container {
  width: 100%;
  height: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: white;
  border-top: 1px #e9e9e9 solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.clear-button {
  padding-right: 24px;
}

.separator-one {
  max-width: 100%;
  border: 1px solid #e9e9e9;
  width: 500px;
}

.separator-two {
  max-width: 100%;
  border: 1px solid #e9e9e9;
  width: 500px;
  margin: 40px auto;
}

.cost-top {
  color: #151515;
  font-size: 24px;
  font-family: Mona-Sans;
  font-weight: 800;
  letter-spacing: 0.5px;
  word-wrap: break-word;
}

.ReservationCostContainer {
  color: #151515;
  font-size: 24px;
  font-family: Mona-Sans;
  font-weight: 800;
  letter-spacing: 0.5px;
  word-wrap: break-word;
}

.clear-new-button {
  display: inline-flex;
}

.deposit-small {
  color: #151515;
  font-size: 18px;
  font-family: Mona-Sans;
  font-weight: 700;
  word-wrap: break-word;
  width: 440px;
}

.cost.cost-right {
  display: block;
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: right;
  padding-top: 24px;
}

.right {
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 100%;
  align-items: center;
}

.cost.cost-right {
  display: inline-flex;
  font-family: Mona-Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  /* text-align: right; */
  max-width: 240px;
  padding-top: 24px;
  align-items: flex-end;
  margin-left: auto;
  text-align: right;
  flex-direction: column-reverse;
  /* padding-right: 24px; */
  align-self: flex-end;
}
