@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('fonts/Mona-Sans.woff2'); */

@font-face {
  font-family: 'Mona Sans';
  src: url('./fonts/Mona-Sans.woff2') format('woff2 supports variations'),
    url('./fonts/Mona-Sans.woff2') format('woff2');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

html {
  font-family: 'Mona Sans';
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Mona Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {
  background-color: #f5f5f5;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.label {
  position: absolute;
  width: 20px;
  cursor: pointer;
  pointer-events: all;
  transition-property: transform;
  transition-duration: 0.5s;
}

.power-label {
  width: 40px;
}

.label:hover {
  transform: scale(1.2);
}

.webxr-portrait {
  display: none;
}

@media (any-pointer: coarse) and (orientation: landscape) {
  /* do your own styles */
  .webxr-portrait {
    display: flex;
  }
}

/* transitionProperty: "background-color, box-shadow,transform,filter",
transitionDuration: " 0.5s", */

.reservation-panel {
  @apply max-w-[580px] w-full text-base-text;
}

.summary-container {
  @apply md:px-10 md:py-6 px-10 py-4 bg-white flex-col justify-start items-start gap-6 border;
  @apply border-gray-200;
  border-top: 1px solid rgb(229 231 235 / 1);
}

.form-panel {
  @apply p-8 md:p-10 pb-14 pt-6 text-base-text;
}

.form-paragraph {
  @apply text-sm font-normal tracking-[0.0625rem] m-0 mb-10;
}

.reservation-sidebar {
  @apply h-[100dvh] rounded-none flex flex-col bg-white;
}

.scrollable {
  /* max-width: 580px; */
  height: calc(100vh);
  /* You can adjust the percentage as needed */
  max-height: calc(100vh);
  /* You can also use viewport units for a maximum height */
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 0 !important;
  transition: max-height 0.5s ease-in-out;
  /* Add any other styles you want for the Box */
}

.scrollable.confirmStep {
  height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.reservation-label {
  @apply text-[0.75rem] tracking-[0.0625rem] font-wide font-normal text-[#383A3A] mb-4;
}

.reservation-input {
  @apply w-full h-12 px-6 bg-[#F1F1F1] text-base-text placeholder:text-[#9A9A9A] rounded-lg;

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.0625rem;
}

.reservation-input::placeholder {
  /* color: red; */
  /* @apply text-[#9A9A9A]; */
}

.reservation-label {
}

.font-wide {
  font-stretch: 125%;
}

.chip {
  @apply bg-[#151515] rounded-[100px] py-[6px] px-4 text-white text-[0.625rem] font-bold font-wide tracking-[0.0125rem] uppercase inline-flex justify-center items-center;
}

a:visited {
  color: inherit;
}

a {
  @apply transition-colors;
  @apply text-inherit;
  &:hover {
    @apply text-brand;
  }
}

@keyframes rotateIn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-90deg);
  }
}

iframe {
  display: none;
}
