.form-container {
  /* Other styles for form container */
  position: relative;
  bottom: 0;

  /* Add other styles as needed */

  .reservation-panel {
    transform: translateX(100%);
    transition: transform 0.7s ease-in-out;
  }
}

.form-container.show {
  top: 0;
  .reservation-panel {
    transform: translateX(0);
  }
}

/* Style for the Close Button */
.form-container .close-button {
  position: relative;
  top: 0;
  /* Add other styles as needed */
  background: white;
  border-radius: 50%;
}

.progress-stepper {
  display: inline !important;
  max-width: 100%;
}
