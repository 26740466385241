/* styles.css */
/* For WebKit-based browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* For Microsoft Edge */
/* Remove the arrows and outline */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* Reservation Overview section */
/* .carType,
.varTagline,
.divider,
.cost,
.dap,
.recommended,
.dapContainer,
.dapPackage,
.dapPrice,
.dapPara,
.form-container,
.dueTodayContainer,
.dueToday {
  font-size: 14px;
  margin: 0;
  padding: 0;
} */


.dapPara {
  font-family: Mona-Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
}

/* Update h2 styles */
h2 {
  margin: 0; /* Remove margin from h2 */
}

/* Increase font size for h3 */
.cost {
  font-size: 18px; /* Increase font size for h3 */
}

/* Reservation Deposit and refundable alignment */
.deposit {
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  margin: 0;
  padding: 0;
}

.deposit h4 {
  margin: 0;
  padding: 0;
}

.refundable {
  text-align: right;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

/* Align checkbox, dapPackage, and dapPrice on the same line */
.dapContainer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will push .dapPackage to the start and .dapPrice to the end */
  max-width: 100%;
}

.dapPackage,
.dapPrice {
  margin: 0; /* Remove margin for h5 elements */
  padding: 0;
}

/* Personal Details section */

  
  /* Style first name and last name containers to be on the same line */
  .firstNameContainer,
  .lastNameContainer {
    display: inline-block;
    margin: 0;
  }
  
  /* Style email container to be on its own line */
  .emailContainer {
    margin: 0;
    margin-bottom: 24px;
  }

  .companyContainer {
    padding-bottom: 32px;
  }

  .terms {
    /* margin: 0 auto; */
    text-align: left;
    font-family: Mona-Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .dapPrice {
    font-family: Mona-Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0px;
    margin-left: auto;
  }

  .dueAmount {
    display: inline-flex;
    justify-content: flex-start;
  }

  .dapAdd {
    display: inline-flex;
    /* needs to align right */
    justify-content: flex-end;
  }
