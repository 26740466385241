/* MultiPartForm.css */

/* Responsive styles for the MultiPartForm component */
.multi-part-form {
    width: 580px;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    transition: right 0.75s;
    z-index: 100;
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .multi-part-form {
      width: 580px;
      height: auto;
      position: relative;
      margin-bottom: 20px;
    }
  }
  
  .controlsContainer {
    height: 161px;
    position: sticky;
  }
  
  .termsStyle {
    text-align: center;
    font-weight: 400;
  }

  
.stepper-responsive {
  max-width: 100% !important;
}